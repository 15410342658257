import React, { Component } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import "./carousel.scss";
import SeePrintStatusImg from "common/images/help-img-1.png";
import PrintAnywhereImg from "common/images/help-img-2.jpg";
import DirectionsImg from "common/images/help-img-3.png";
import LeftIcon from "common/images/details-back.svg";
import { Desktop, Touch } from "common/breakpoints";

const contactUsContent = (
	<div>
		Have any problems? Email us
		<span className="email-text">it-stit@nyu.edu</span>
	</div>
);

class CarouselComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentSlide: 0
		};
	}
	render() {
		const settings = {
			dots: false,
			infinite: false,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			prevArrow: (
				<SlideArrowComponent
					currentSlide={this.state.currentSlide}
					classArrow={"previous"}
					text="Previous"
				/>
			),
			nextArrow: (
				<SlideArrowComponent
					currentSlide={this.state.currentSlide}
					classArrow={"next"}
					text="Next"
				/>
			),
			afterChange: currentSlide => {
				this.setState({
					currentSlide: currentSlide
				});
			}
		};
		const helpDetails = [
			{
				title: "See Printer Status",
				content:
					"See real time availability of printers in NYU buildings across campus, including paper and toner status. Buildings in purple have up and running printers.",
				img: SeePrintStatusImg
			},
			{
				title: "Find Nearby Printer",
				content:
					"Sorted by distance, find the closest available printer in seconds. Click on the buildings for details and individual printers’ statuses.",
				img: PrintAnywhereImg
			},
			{
				title: "MobilePrint",
				content:
					"With MobilePrint, students are able to print directly from your computer or phone through email, no NYU wifi required.",
				img: DirectionsImg
			},
			{
				title: "Contact Us",
				content: contactUsContent,
				img: null
			}
		];
		return (
			<div>
				<Slider {...settings}>
					{helpDetails.map((help, index) => {
						let tabIndex = -1;
						if (settings.prevArrow.props.currentSlide === index) tabIndex = 0;
						return (
							<div className="wrapper" key={index}>
								<div className="content">
									<div
										style={{ backgroundImage: `url(${help.img})` }}
										className={index === 3 ? "no-img" : "help-img"}
									/>
									{/* Do not show any image for contact us slide - index = 3 */}
									<div className="help-title" tabIndex={tabIndex}>
										{help.title}
									</div>
									<div className="help-content" tabIndex={tabIndex}>
										{help.content}
									</div>
								</div>
							</div>
						);
					})}
				</Slider>
				<Desktop>
					<div className="current-slide-details-desktop">
						{this.state.currentSlide + 1}/4
					</div>
				</Desktop>
				<Touch>
					<div className="current-slide-details-mobile">
						{this.state.currentSlide + 1}/4
					</div>
				</Touch>
			</div>
		);
	}
}

const SlideArrowComponent = ({
	to,
	classArrow,
	onClick,
	text,
	currentSlide
}) => {
	if (text === "Next" && currentSlide === 3) {
		return <div />;
	}
	if (text === "Previous" && currentSlide === 0) {
		return <div />;
	}
	return (
		<button
			type="button"
			className={classArrow + " button type-b"}
			onClick={onClick}
			aria-label={to}
			tabIndex={0}
		>
			<div
				style={{
					backgroundImage: `url(${LeftIcon})`
				}}
				className={text === "Next" ? "next-img" : "prev-img"}
			></div>
		</button>
	);
};

SlideArrowComponent.propTypes = {
	classArrow: PropTypes.string,
	to: PropTypes.string,
	onClick: PropTypes.func,
	text: PropTypes.string,
	currentSlide: PropTypes.number
};

export default CarouselComponent;
